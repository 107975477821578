<template>
  <div v-if="loaded">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <Breadcrumb class="row justify-content-center mt-4" :crumbs="crumbs" @selected="selected" />
        </div>
      </div>
    </div>
    <div class="container product-view">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <figure class="image mb-6">
            <carousel v-bind:product="product"> </carousel>
          </figure>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col-sm-12 col-md-9">
              <h1 class="product-title">{{ product.name }}</h1>
              <div v-html="product.description" class="product-description"></div>
              <div class="product-price">{{
                new Intl.NumberFormat('de-CH', {
                  style: 'currency', currency: 'CHF',
                  trailingZeroDisplay: 'stripIfInteger'
                }).format(product.price)
              }}</div>
              <div class="product-subprice">exkl. MwSt.</div>
              <div class="product-subprice">Lieferung sofern vorrätig: 3-5 Arbeitstage
                <Tooltip />
              </div>
            </div>
            <div class="col-sm-12 col-md-9">
              <div v-if="product.deliverycontent" class="product-delivery-content">
                <div class="product-delivery-content-title">Lieferumfang</div>
                <div v-html="product.deliverycontent" class="package-box"></div>
              </div>
              <div v-if="product.product_is_inactive" class="product-availability">
                <i class="bi bi-info-circle"></i>
                <div class="product-availability-text">Produkt nicht lieferbar.</div>
              </div>
              <div class="bundle-buttons">
                <input type="hidden" class="input" min="1" v-model="quantity" />
                <button class="add-to-cart" v-if="!product.product_is_inactive" @click="addToCart">
                  <i class="icon-cart"></i>In den Warenkorb
                </button>
                <button class="button-dark">
                  <a href="tel:+41 71 311 44 00"><i class="icon-phone"></i>Wir helfen gerne</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast v-bind:title="toasttitle" v-bind:body="toastbody" />
    </div>
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import Carousel from "@/components/Carousel.vue";
import Tooltip from "@/components/Tooltip.vue";
import Toast from "@/components/Toast.vue";
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: "Product",
  components: {
    Spinner,
    Carousel,
    Tooltip,
    Toast,
    Breadcrumb,
  },
  data() {
    return {
      loaded: false,
      product: {},
      quantity: 1,
      toasttitle: "scanner4you",
      toastbody: "",
      crumbs: ['Kaufen', 'Zubehör'],
      deliverycontent: "",
    };
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      const category_slug = this.$route.params.category_slug;
      const product_slug = this.$route.params.product_slug;

      await axios
        .get(`/api/v1/products/accessory/${product_slug}`)
        .then((response) => {
          this.product = response.data;
          this.loaded = true
          this.crumbs.push(this.product.name)
          document.title = this.product.name + " - scanner4you";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }
      const item = {
        product: this.product,
        quantity: this.quantity,
      };
      this.$store.commit("addToCart", item);
      this.toastbody = "Das Zubehör wurde dem Warenkorb hinzugefügt."
      $('.toast').toast('show');
    },
  },
};
</script>
